.modals {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    top: 150%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    -webkit-transition: top 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55), visibility 0.1s ease 0.7s;
    -o-transition: top 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55), visibility 0.1s ease 0.7s;
    transition: top 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55), visibility 0.1s ease 0.7s;
    padding: 0;
    visibility: hidden;
    overflow-y: auto;
    height: 100%;
    .modal-content-my {
        opacity: 0;
        -webkit-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 600px;
        padding: 30px 30px 50px;
        background: linear-gradient(to right, #5bac21, #01a0c6, #0d3d98);
    }
    .modal_overlay {
      position:absolute;
      width:100%;
      height:100%;
      z-index:1;
      background-color: rgba($grey, 0.5);
      -webkit-transform: scale(0.5);
          -ms-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
      transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
      -o-transition: opacity 0.5s ease, transform 0.5s ease;
      transition: opacity 0.5s ease, transform 0.5s ease;
      transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
      cursor: pointer;
      opacity: 0;
      background: rgba($grey, .85);
  }
    &.active_js {
        visibility: visible;
        top: 0;
        -webkit-transition: top 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -o-transition: top 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: top 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        .modal-content-my {
            opacity: 1;
            -webkit-transition: opacity 0.4s ease 1s;
            -o-transition: opacity 0.4s ease 1s;
            transition: opacity 0.4s ease 1s;
        }
        .modal_overlay {
            opacity: 1;
            -webkit-transform: scale(1);
                -ms-transform: scale(1);
                    transform: scale(1);
            -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
            transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
            -o-transition: opacity 0.3s ease, transform 0.3 ease;
            -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3 ease;
            transition: opacity 0.3s ease, -webkit-transform 0.3 ease;
            transition: opacity 0.3s ease, transform 0.3 ease;
            transition: opacity 0.3s ease, transform 0.3 ease, -webkit-transform 0.3 ease;
            transition: opacity 0.3s ease, transform 0.3 ease, -webkit-transform 0.3s ease;
        }
    }
}

.modal-title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 40px;
    line-height: 1.2;
}

.modal-subtitle {
    margin-bottom: 30px;
    text-align: center;
    font-size: 26px;
}

.close-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 50px;
    top: 50px;
    cursor: pointer;
    @extend %transition;
    z-index: 100;
    svg {
        pointer-events: none;
        fill: $white;
    }
    &:hover,
    &:focus,
    &:active {
        -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
                transform: rotate(90deg);
        @extend %transition;
        svg {
            fill: $yellow;
        }
    }
}

.auth-error,
.auth-restored {
    color: $red;
    font-size: 14px;
    padding: 5px 0;
}

.cab_buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px;
    max-width: 320px;
    margin: 0 auto;
    font-size: 14px;
    color: $bg-grey;
    &.text {
        text-align: center;
        padding-top: 0;
    }
    .text {
        font-size: 14px;
        color: $bg-grey;
        padding-right: 10px;
    }
    .modal-link {
        font-family: $ff-bold;
        font-size: 16px;
    }
}

.modal-content-cover {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    max-width: 600px;
    .cab-content {
        display: none;
        &.active_js {
            display: block;
        }
    }
    .success {
        font-size: 18px;
    }
}


// Модалка с ютубом на главной !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.modal-iframe {
    .modal-content-my {
        max-width: 87%;
        position:relative;
        padding-bottom: 38.25%;
        height:0;
        background: transparent;
         iframe {
             position:absolute;
             top:0;
             left:0;
             width:100%;
             height:100%;
         }
    }
}

.modal-forms {
    max-width: 300px;
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .close-btn {
        right: 10px;
        top: 10px;
    }
}

@media (max-width: 1023px) {
    .modal-title {
        font-size: 34px;
    }
}

@media (max-width: 767px) {
    .modal-iframe {
        .modal-content-my {
            padding-bottom: 78.25%;
        }
    }
    .modal-title {
        font-size: 28px;
        margin-bottom: 15px;
    }
    .modal-subtitle {
        margin-bottom: 15px;
        font-size: 22px;
    }
}
