// Модуль на странице поиска тура
.cover-modules {
    margin: 50px 0;
    font-size: 14px;
    overflow-x: auto;
    table {
        width: 100%;
    }
    .itt_in_middel {
        margin: 0 auto;
    }
}

html body #tour_search_module * .extended_package_search_form .frame_block h1.title,
html body #tour_search_module * .extended_hike_search_form .frame_block h1.title {
    font-size: 40px;
    text-transform: uppercase;
}

html body #tour_search_module table {
    margin: 0 auto !important;
}

html body #isolate * .extended_hike_search_form .itt_content .pager-sub .btn-search input {
    background-color: #69AF28 !important;
}

html body #tour_search_module *,
html body #isolate {
    font-size: 14px !important;
}


html body #itTourWidget .fd-nav-mobile,
html body #itTourWidgetPopup .fd-nav-mobile {
    z-index: 13 !important;
}

html body #itTourWidget .fd-detail {
    position: static !important;
}

html body #itTourPriceWidget .ss-content,
html body #itTourPriceWidgetPopup .ss-content {
    // padding: 0;
    // padding-right: 20px;
}
// cover-modules-cruise
.cover-modules-cruise {
    margin: 30px 0;
    .selection-filter,
    .selection-filter-name-top,
    .selection-filter-swow {
        background: #0158A7;
    }
    .totals {
        background: #0158A7;
    }
    .description-price {
        border-color: #0158A7;
    }
    .description-price-text {
        background-color: #0158A7;
    }
    .block-selection-filter-right {
        padding-left: 15px;
        padding-right: 15px;
    }
}
// cover-modules-tour-list
.cover-modules-tour-list {
    margin: 0 0 80px 0;
}


@media (max-width: 1023px) {
    .scroll-modlues {
        overflow-x: auto;
    }
}

@media (max-width: 400px) {
    .cover-modules-cruise {
        .block-selection-filter-right {
            padding-left: 15px;
            padding-right: 15px;
            float: none;
        }
    }
}
