//  breadcrumbs Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.breadcrumbs-cover {
    background: $bg-grey;
    padding: 30px 0;
    .container-flex {
        justify-content: flex-start;
    }
    .breadcrumbs {
        background: transparent;
        text-align: center;
        border-radius: 4px;
        padding: 10px 0;
        margin: 0 -10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .crumb-li {
        display: inline-block;
    }
    .crumb  {
        color: $text;
        position: relative;
        display: block;
        padding: 0 10px;
        svg {
            fill: $text;
            @extend %transition;
        }
        &::after {
            content: '/\00a0';
            position: absolute;
            display: block;
            color: $text;
            right: -7px;
            top: 0;
            @extend %transition;
        }
        &:hover,
        &:focus,
        &:active {
            color: $salat;
            svg {
                fill: $salat;
            }
        }
        &.js-lastcrumb {
            cursor: default;
            pointer-events: none;
            &::after {
                content: none;
            }
        }
    }
    &.white {
        justify-content: center;
        .breadcrumbs {
            background: rgba($black, .4);
            padding: 10px;
            margin: 0;
        }
        .crumb  {
            color: $text-light;
            svg {
                fill: $text-light;
                @extend %transition;
            }
            &::after {
                color: $text-light;
            }
            @extend %transition;
            &:hover,
            &:focus,
            &:active {
                color: $salat;
                svg {
                    fill: $salat;
                }
            }
            &.js-lastcrumb {
                cursor: default;
                pointer-events: none;
                &::after {
                    content: none;
                }
            }
        }
    }
    .link-svg {
        width: 20px;
        height: 20px;
        display: block;
    }
}
//  breadcrumbs finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// pagination-cover Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.pagination-cover {
    .container-cover {
        text-align: center;
    }
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pagination > li {
        padding: 0 5px;
    }
    .pagination > li > a,
    .pagination > li > span {
        color: $text;
        display: block;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %transition;
        &:hover,
        &:focus {
            background: rgba($yellow, 0.6);
        }
    }
    .pagination > .disabled > span,
    .pagination > .disabled > span:hover,
    .pagination > .disabled > span:focus,
    .pagination > .disabled > a,
    .pagination > .disabled > a:hover,
    .pagination > .disabled > a:focus {
        color: $text-light;
        background: transparent;
    }
    .pagination > .active > a,
    .pagination > .active > a:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span,
    .pagination > .active > span:hover,
    .pagination > .active > span:focus {
        background: $yellow;
        border-color: $yellow;
        color: $white;
    }
}
//  pagination-cover Finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// admin-desc Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.admin-desc {
    p {
        color: $text;
        font-size: 15px;
        margin: 0 0 10px;
        text-align: justify;
        line-height: 1.5;
    }
    ul {
        list-style-type: none;
        list-style-position: inside;
        margin: 0 0 10px;
        li {
            color: $text;
            font-size: 15px;
            margin: 0 0 5px;
            position: relative;
            padding-left: 15px;
            line-height: 1.5;
            &::before {
                content: '';
                display: block;
                width: 3px;
                height: 3px;
                background: transparent;
                border: 2px solid $salat;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 6px;
            }
        }
    }
    p {
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        iframe {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
    h2, h3, h4, h5, h6 {
        color: $title;
        font-family: $ff-bold;
        letter-spacing: -0.8px;
        font-size: 20px;
    }
    h2 {
        font-size: 24px;
        margin: 20px 0 15px 0;
    }
    h3 {
        font-size: 24px;
        margin: 20px 0 15px 0;
    }
}
.margin-desc + .margin-desc {
    margin-top: 40px;
}
// admin-desc Finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// VIdeo Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.video-item {
    position:relative;
    padding-bottom:56.25%;
    iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}
// Video Finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// gallery-item !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.gallery-item {
    position: relative;
    cursor: pointer;
    .gallery-item-mask {
        position: absolute;
        top: 0;
        bottom: 100%;
        left: 0;
        right: 0;
        pointer-events: none;
        background: linear-gradient(to bottom, $salat, $light-blue, $blue);
        opacity: .7;
        transition: 0s;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .gallery-item-mask-svg {
        svg {
            width: 50px;
            height: 50px;
            fill: $white;
        }
    }
    &:hover,
    &:focus,
    &:active {
        .gallery-item-mask {
            bottom: 0;
            @extend %transition;
        }
    }
}
// gallery-item !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
