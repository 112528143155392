.header {
    -webkit-box-shadow: 0 10px 33px rgba(0,0,0,.1);
    box-shadow: 0 10px 33px rgba(0,0,0,.1);
    &.landing {
        display: none;
    }
}
.header-top {
    background: $grey;
    color: $white;
    max-width: 1920px;
    margin: 0 auto;
}

.menu-top {
    margin: 0 -10px;
    .menu-top-link {
        display: block;
        padding: 10px;
    }
}

.header-bottom {
    background: $white;
    padding: 5px 0;
    max-width: 1920px;
    margin: 0 auto;
    .logo {
        padding: 0 15px;
        margin-right: auto;
    }
    .bottom-cover {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .header-downline-cover {
        margin-top: auto;
    }
    .header-downline {
        display: flex;
        margin: 0 -15px;
        align-items: center;
    }
    .bottom-menu {
        padding: 0 15px;
        margin-left: auto;
    }
    .header-name-company {
        margin: 0 auto;
        font-size: 24px;
        color: $blue;
        font-family: $ff-bold;
    }
    .header-topline-cover {
        margin-top: auto;
        margin-bottom: auto;
    }
    .text-subscribe {
        white-space: nowrap;
        font-size: 13px;
        color: $title;
        padding-bottom: 10px;
    }
    .cover-subscribe {
        margin-left: auto;
    }
    .header-address-cover {
        margin: 0 auto;
    }
}

.bottom-menu {
    display: flex;
    justify-content: flex-end;
    margin: 0 -15px;
    align-items: center;
    .bottom-menu-link {
        display: block;
        padding: 10px 7.5px;
        text-transform: uppercase;
        font-family: $ff-bold;
        font-size: 13px;
        font-weight: 500;
        &.link-flex {
             display: flex;
             align-items: center;
             .link-text {
                 display: none;
             }
             .link-svg {
                 display: block;
                 width: 20px;
                 height: 20px;
             }
         }
    }
}

.header-address {
    display: flex;
    margin: 0 -10px;
    .item-address {
        padding: 0 10px;
        border-left: 2px solid $yellow;
        p {
            margin: 0 0 5px;
        }
    }
    .link-address {
        cursor: pointer;
        color: $title;
    }
}

// Социальные сети !!!!!!!!!!!!!!!!!!!!!!!1
.social-block {
    margin: 0 -7.5px;
    align-items: center;
    justify-content: flex-end;
    .social-link {
        display: block;
        background: $white;
        border-radius: 10px;
        margin: 0 7.5px;
        height: 25px;
        width: 25px;
        svg {
            width: 25px;
            height: 25px;
            @extend %transition;
            transform: scale(1);
        }
        &:hover {
            svg {
                transform: scale(1.1);
            }
        }
        &.youtube {
            svg {
                fill: $red;
            }
        }
    }

}

// lang !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.lang {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        width: 50px;
        cursor: pointer;
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 45%;
            width: 10px;
            height: 10px;
            -webkit-transition: -webkit-transform 0.3s ease;
            transition: -webkit-transform 0.3s ease;
            -o-transition: transform 0.3s ease;
            transition: transform 0.3s ease;
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        }
        .language-li {
            display: block;
            border-left: none;
        }
        .language-block {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            display: none;
            z-index: 3;
            background: $light-black;
            width: 50px;
        }
        .link-lang  {
            text-align: center;
            display: block;
            padding: 5px 10px;
            font-size: 14px;
            color: $white;
        }
        .active-lang {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
               -moz-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            .change_lang_span {
                font-size: 14px;
                padding-right: 10px;
                color: $white;
            }
        }
    }

// Cabinet
.cabinet {
    cursor: pointer;
    .flex-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        svg {
            fill: $white;
        }
    }
    .flex-text {

    }
}

.header {
    position: fixed;
    z-index: 15;
    left: 0;
    right: 0;
    top: 0;
    transition: top 0.3s ease;

}
