@font-face {
 font-family: 'Proxima Nova Th';
 src: url('/fonts/proximanovat-thin-webfont.eot');
 src: url('/fonts/ProximaNovaT-Thin.woff') format('woff'),
      url('/fonts/ProximaNovaT-Thin.ttf')  format('truetype'),
      url('/fonts/ProximaNovaT-Thin.svg') format('svg');
 font-weight: normal;
 font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('/fonts/lato/lato-regular-webfont.eot');
    src: url('/fonts/lato/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/lato/lato-regular-webfont.woff2') format('woff2'),
         url('/fonts/lato/lato-regular-webfont.woff') format('woff'),
         url('/fonts/lato/lato-regular-webfont.ttf') format('truetype'),
         url('/fonts/lato/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latolight';
    src: url('/fonts/lato/lato-light-webfont.eot');
    src: url('/fonts/lato/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/lato/lato-light-webfont.woff2') format('woff2'),
         url('/fonts/lato/lato-light-webfont.woff') format('woff'),
         url('/fonts/lato/lato-light-webfont.ttf') format('truetype'),
         url('/fonts/lato/lato-light-webfont.svg#lato_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('/fonts/lato/lato-bold-webfont.eot');
    src: url('/fonts/lato/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/lato/lato-bold-webfont.woff2') format('woff2'),
         url('/fonts/lato/lato-bold-webfont.woff') format('woff'),
         url('/fonts/lato/lato-bold-webfont.ttf') format('truetype'),
         url('/fonts/lato/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}
