// Блок ХОЧУ В ТУР Старт!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.block-want_tour {
    .flex-img {
        flex: 0 0 350px;
        margin-right: auto;
        padding: 0 15px;
    }
    .form-cover {
        flex: 1;
        padding: 0 15px;
    }
    .container-flex {
         align-items: center;
    }
}
// Блок ХОЧУ В ТУР Финиш !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Блок с подпиской START!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.section-blue-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .main-title {
        margin-bottom: 10px;
    }
}

.social-subscribe {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -22px;
    margin-top: 30px;
    .item-subscribe {
        padding: 0 22px;
    }
    .subscribe-link {
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %transition;
        svg {
            @extend %transition;
            width: 45px;
            height: 45px;
            transform: scale(1);
        }
        &:hover,
        &:active,
        &:focus {
            svg {
                transform: scale(1.1);
            }
        }
    }
}
// Блок с подпиской FINISH!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
@media (max-width: 1200px) {
    .block-want_tour {
        .container-flex {
            display: block;
        }
        .flex-img {
            margin-bottom: 20px;
        }
        .inputs-list {
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}
