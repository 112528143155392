.header_mobile {
    box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
    background: $white;
    padding: 5px 0;
    .container-cover {
        align-items: center;
    }
    .link-tel-cover + .link-tel-cover {
      margin: 10px 0 0;
    }
    .item-address {
      margin: 0 0 20px;
    }
    .lang .active-lang .change_lang_span {
        color: $text;
    }
    .logo {
        padding: 0 15px;
        max-width: 130px;
        margin-right: auto;
    }
    .mobile-btn-cover {
        padding: 0 15px;
        margin-left: auto;
    }
    .social-block {
        .social-link {
            height: 35px;
            width: 35px;
            svg {
                width: 35px;
                height: 35px;
            }
        }
    }
    .link-flex  {
        .link-svg {
            display: none;
        }
    }
    .lang {
        height: 50px;
        .span-svg {
            svg {
                fill: $text;
            }
        }
        .link-lang {
            color: $text;
        }
        .language-block {
            background: $white;
            border: $border;
        }
    }
    .cabinet-cover {
        padding: 15px;
        background: $grey;
    }
    .cabinet {
        justify-content: flex-start;
    }
    .social-block-cover {
        padding: 0 15px;
        text-align: center;
    }
    .social-title {
        margin-bottom: 10px;
        color: $title;
        font-size: 18px;
        font-family: $ff-bold;
    }
    .social-block  {
        align-items: center;
        justify-content: center;
    }
}

.mobile-btn {
    display: block;
    position: relative;
    width: 40px;
    height: 34px;
    background: transparent;
    padding: 5px;
    border: 2px solid $salat;
    @extend %transition;
    cursor: pointer;
    .line {
        position: absolute;
        display: block;
        left: 5px;
        right: 5px;
        height: 2px;
        background: $salat;
        @extend %transition;
    }
    .line-1 {
        top: 5px;
    }
    .line-2 {
        top: 14px;
        opacity: 1;
    }
    .line-3 {
        bottom: 5px;
    }
    &:hover,
    &:active,
    &:focus {
        border: 2px solid $salat;
    }
}

// mobile-menu !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.mobile-menu-cover {
       position: fixed;
       top: 0;
       right: 100%;
       left: 100%;
       width: 100%;
       bottom: 0;
       z-index: 100;
       background: rgba($black, .6);
       cursor: pointer;
       @extend %transition;
       &.js_active {
           @extend %transition;
           right: 0;
           left: 0;
       }
   }
   .mobile-menu-topline {
       background: $white;
       padding: 5px 0;
       -webkit-box-shadow: 0 1px 2px rgba(123, 127, 130, 0.3);
               box-shadow: 0 1px 2px rgba(123, 127, 130, 0.3);
       position: relative;
       z-index: 1;
       .container-flex {
           -webkit-box-pack: justify;
           -webkit-justify-content: space-between;
              -moz-box-pack: justify;
               -ms-flex-pack: justify;
                   justify-content: space-between;
           -webkit-box-align: center;
           -webkit-align-items: center;
              -moz-box-align: center;
               -ms-flex-align: center;
                   align-items: center;
       }
   }
   .topline-right-item {
       display: -webkit-box;
       display: -webkit-flex;
       display: -moz-box;
       display: -ms-flexbox;
       display: flex;
       -webkit-box-align: center;
       -webkit-align-items: center;
          -moz-box-align: center;
           -ms-flex-align: center;
               align-items: center;
   }
   .btn-close-mobmenu {
       cursor: pointer;
       padding: 16px 14px 11px 14px;
       background: transparent;
       border: none;
       outline: none;
       svg {
           width: 30px;
           height: 30px;
           fill: $salat;
       }
       &:hover,
       &:focus,
       &:active {
           svg {
               fill: $salat;
           }
       }
   }
   .menu-cover-mini {
       background: $white;
       -webkit-box-shadow: 0 1px 2px rgba(123, 127, 130, 0.3);
               box-shadow: 0 1px 2px rgba(123, 127, 130, 0.3);
       padding: 20px 10px;
       max-width: 300px;
       overflow-y: auto;
       height: 100%;
       margin-left: auto;
       padding-bottom: 100px;
   }
   .menu-mobile {
       position: relative;
   }
   .cabinet-mobile-cover {
       .btn-flex  {
           padding: 8px 15px;
           display: -webkit-box;
           display: -webkit-flex;
           display: -moz-box;
           display: -ms-flexbox;
           display: flex;
           -webkit-box-align: center;
           -webkit-align-items: center;
              -moz-box-align: center;
               -ms-flex-align: center;
                   align-items: center;
           border-radius: 30px;
       }
       .svg-text {
           padding-left: 10px;
           font-size: 18px;
       }
   }
   .catalog-menu-cover {
       position: static;
   }
   .nav-menu {
       padding: 20px 0;
   }
   .nav-menu-link {
       display: block;
       padding: 5px 10px;
       font-size: 18px;
       font-family: $ff-text;
       color: $title;
       border-bottom: 1px solid transparent;
       &.hover,
       &.active-link {
           color: $light-blue;
           border-color: $light-blue;
       }
   }
