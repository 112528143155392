*{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    outline: none;
 }

 html {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100%;
    margin: 0;
    padding: 0;
 }

 p iframe, p img {
    width: auto !important;
    margin: 0 auto;
    max-width: 100%;
    display: inline-block;
    height: auto !important;
 }

 p img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
 }

 p iframe {
    min-height: 400px;
    width: 100% !important;
    margin: 0 auto;
 }

 body {
    font-size: $font-size;
    font-family: $ff-text;
    margin: 0;
    padding: 0;
    color: $text;
    line-height: 1.3;
    max-width: 1920px;
    margin: 0 auto;
    min-height: 100%;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    &.overflow {
        overflow: hidden;
    }
 }

 h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: 1.2;
 }

 a {
    text-decoration: none;
 }

 a, label {
    -webkit-tap-highlight-color: transparent;
 }

 ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
 }

 input, button {
    border: none;
    outline: none;
    &:hover,
    &:focus,
    &:active,
    &:focus-within {
        border: none;
        outline: none !important;
        -webkit-box-shadow: none;
                box-shadow: none;
    }
 }

 button::-moz-focus-inner {
  border: 0 !important;
 }

 textarea {
    resize: none;
    border: none;
    outline: none;
    &:hover,
    &:focus,
    &:active {
        border: none;
        outline: none;
        -webkit-box-shadow: none;
                box-shadow: none;
    }
 }

 video {
    display: block;
 }

 a:hover, a:focus, a:active, a:visited {
    text-decoration: none;
    outline: none;
 }

 .site {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    min-height: 100%;
    &.js_active {
        overflow: hidden;
    }
 }

 .overlay_main {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    bottom: 0;
    background: rgba($text,.6);
    opacity: 0;
    cursor: pointer;
    z-index: -1;
 }

 .main-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    position: relative;
 }

 // up_button !!!!!
 #up_button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    right: 30px;
    bottom: 30px;
    visibility: hidden;
    z-index: 7;
    border-radius: 4px;
    cursor: pointer;
    background: $yellow;
    svg {
        fill: $white;
        width: 20px;
        height: 20px;
    }
    &.js_visible {
        visibility: visible;
    }
 }

 #home {
     display: -webkit-box;
     display: -webkit-flex;
     display: -moz-box;
     display: -ms-flexbox;
     display: flex;
     width: 40px;
     height: 40px;
     -webkit-box-pack: center;
     -webkit-justify-content: center;
     -moz-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     -webkit-box-align: center;
     -webkit-align-items: center;
     -moz-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     position: fixed;
     right: 30px;
     bottom: 150px;
     z-index: 7;
     border-radius: 4px;
     cursor: pointer;
     background: $light-blue;
     svg {
         fill: $white;
         width: 20px;
         height: 20px;
     }
}

.fixed-callback {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    right: 30px;
    bottom: 90px;
    z-index: 7;
    border-radius: 4px;
    cursor: pointer;
    background: $red;
    @extend %transition;
    animation: pulse 2s infinite;
    svg {
        fill: $white;
        width: 20px;
        height: 20px;
        @extend %transition;
    }
}

 .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    &.big {
        width: 100%;
    }
    &.not_margin {
        margin: 0;
    }
 }

 .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba($black, 0.3);
    z-index: 16;
    cursor: pointer;
    &.js_active {
        bottom: 0;
    }
}
 // CONTAINER !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 .section-padding {
    padding: 80px 0;
    &.with-block {
        padding-bottom: 50px;
    }
    .no-bottom {
        padding-bottom: 0;
    }
 }

 .section-margin {
    margin: 80px 0;
 }

 .section-color:nth-child(even) {
     background: $bg-grey;
     &.section-gradient {
         // background: linear-gradient(to right, rgba(1, 160, 198, .5), rgba(13, 61, 152, .8));
         // background: linear-gradient(to right, rgba(255, 191, 0, .7), rgba(91, 172, 33, .8));
          background: linear-gradient(to right, rgba(255, 191, 0, .85), rgba(255, 0, 0, .85));
     }
 }

 .bg-grey {
     background: $bg-grey;
 }

 .section-gradient {
     // background: linear-gradient(to right, rgba(1, 160, 198, .5), rgba(13, 61, 152, .8));
     // background: linear-gradient(to right, rgba(255, 191, 0, .7), rgba(91, 172, 33, .8));
      background: linear-gradient(to right, rgba(255, 191, 0, .85), rgba(255, 0, 0, .85));
 }

 .container-cover {
    width: 100%;
    max-width: 1330px;
    padding: 0 15px;
    margin: 0 auto;
 }

 .container-max {
    width: 100%;
    margin: 0 auto;
    position: relative;
 }

 .no-padding {
    padding: 0;
 }

 .margin-minus {
    margin: 0 -15px;
 }

 .container-flex {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    &.nowrap {
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
    &.between {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
           -moz-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    &.start {
        justify-content: flex-start;
    }
    &.end {
        justify-content: flex-end;
    }
 }

 .flex-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
 }
 .heigth-100 {
     height: 100%;
 }

.flex-cover {
    padding: 0 15px;
}
 .block-half {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
 }

 .block-third {
    width: 33.3%;
    padding: 0 15px;
    margin-bottom: 30px;
 }

 .block-fourth {
    width: 25%;
    padding: 0 15px;
    margin-bottom: 30px;
 }

 .block-big {
    width: 66.7%;
    padding: 0 15px;
    margin-bottom: 30px;
 }

 .block-big-seven {
     width: 75%;
     padding: 0 15px;
     margin-bottom: 30px;
 }

.block-100 {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
}

.no-block-mg {
    margin-bottom: 0;
}

.container-main + .container-main {
    margin-top: 30px;
}

@media (max-width: 1200px) {
    .block-fourth {
        width: 33.3%;
    }
    .block-big-seven {
        width: 100%;
    }
}

@media (max-width: 1023px) {
    .section-padding {
       padding: 50px 0;
       &.with-block {
           padding-bottom: 20px;
       }
       .no-bottom {
           padding-bottom: 0;
       }
    }
    .block-fourth {
        width: 50%;
        max-width: 390px;
    }
    .block-third {
        width: 50%;
        max-width: 390px;
    }
}

@media (max-width: 600px) {
    .section-padding {
       padding: 30px 0;
       &.with-block {
           padding-bottom: 15px;
       }
       .no-bottom {
           padding-bottom: 0;
       }
    }
    .block-fourth,
    .block-third  {
        width: 100%;
        margin-bottom: 15px;
    }
    .block-half {
       width: 100%;
       margin-bottom: 15px;
    }
    .block-100  {
         margin-bottom: 15px;
    }
    .no-block-mg {
        margin-bottom: 0;
    }
}
 // title !!!!!!!!!!!!!!!!!!!!!!!!
 .main-title {
     font-family: $ff-text;
     color: $title;
     margin-bottom: 30px;
     font-size: 40px;
     display: block;
}
.after-title {
    font-size: 30px;
    font-family: $ff-text;
    display: inline-block;
    margin-bottom: 10px;
}

 .with-line {
     position: relative;
     padding-bottom: 20px;
     &::after {
         content: '';
         position: absolute;
         display: block;
         bottom: 0;
         left: 50%;
         transform: translateX(-50%);
         width: 150px;
         height: 3px;
         border-radius: 3px;
         background: $yellow;
     }
 }

.subtitle {
    font-size: 18px;
    color: $text-light;
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 30px;
    p {
        margin: 0;
    }
}

 .sidebar-title {
     color: $title;
     font-size: 24px;
     margin-bottom: 30px;
     font-family: $ff-bold;
}

 .page-title {
    color: $title;
    font-size: 30px;
    font-family: $ff-bold;
    margin-top: 25px;
    margin-bottom: 25px;
 }

 .other-title {
     color: $title;
     font-size: 30px;
     font-family: $ff-bold;
     margin-bottom: 30px;
     text-align: center;
 }

 .center-title {
     text-align: center;
 }

 .white-title {
     color: $white;
 }

 .red-title {
     color: $red;
 }


 @media (max-width: 1023px) {
      .main-title {
          font-size: 34px;
          margin-bottom: 20px;
      }
 }

@media (max-width: 600px) {
     .main-title {
         font-size: 28px;
         margin-bottom: 15px;
     }
     .sidebar-title {
         margin-bottom: 15px;
     }
     .page-title {
         font-size: 26px;
         margin-top: 15px;
         margin-bottom: 15px;
     }
     .other-title  {
        font-size: 24px;
        margin-bottom: 15px;
     }
     .subtitle {
         font-size: 15px;
         margin-bottom: 15px;
     }
     .after-title {
         font-size: 24px;
     }
}
 // BTN !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 .span-svg {
     display: block;
     width: 10px;
     height: auto;
     svg {
         fill: $white;
         width: 10px;
         height: 10px;
     }
 }
 .link-block {
    display: block;
 }

 .link-after {
      @include pseudo-fon;
 }

.link-white-salat {
    color: $white;
    @extend %transition;
    &:hover,
    &:focus,
    &:active,
    &.active-link {
        color: $light-blue;
    }
}

.link-text-salat {
    color: $title;
    @extend %transition;
    svg {
        fill: $title;
        @extend %transition;
    }
    &:hover,
    &:focus,
    &:active,
    &.active-link {
        color: $light-blue;
        svg {
            fill: $light-blue;
        }
    }
}

.link-blue-green {
    color: $salat;
    @extend %transition;
    &:hover,
    &:focus,
    &:active {
        color: $green;
    }
}

.link-tel {
    display: inline-block;
    font-family: $ff-bold;
}

.link-tel + .link-tel {
    margin-left: 7.5px;
}

 .btn-cover {
     text-align: center;
 }

 .btn {
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    border-radius: 0;
 }
 .btn-my {
     display: inline-block;
     line-height: 1;
     cursor: pointer;
     border-radius: 0;
 }

 .btn-middle {
    padding: 12px 24px;
 }

 .btn-big {
     padding: 20px 35px;
     font-weight: 700;
     font-family: $ff-bold;
     font-size: 16px;
     text-transform: uppercase;
     text-align: center;
 }

 .btn-block {
    display: block;
    width: 100%;
 }

 .btn-form-block {
     display: block;
     text-align: center;
     padding: 12px 24px;
     width: 100%;
 }

 .btn-blue {
     color: $white;
     background: linear-gradient(to right, $salat, $light-blue, $blue);
     border: 0 solid transparent;
     box-shadow: 0px 0px 30px rgba(0,0,0,0.16);
     background-size: 180%;
     background-position-x: 30%;
     @extend %transition;
     &:hover {
         color: $white;
         background-position-x: 90%;
         border-color: transparent;
     }
     &:focus,
     &:active {
         color: $white;
         background-position-x: 90%;
         border-color: transparent;
         background: linear-gradient(to right, $salat, $light-blue, $blue);
     }
 }

 .btn-gradient {
     color: $white;
     background: linear-gradient(to bottom, $blue, $light-blue, $blue);
     background-size: 200%;
     background-position: 0 0;
     border: 0 solid transparent;
     @extend %transition;
     &:hover {
         color: $white;
         background-position: 0 33px;
         border-color: transparent;
     }
     &:focus,
     &:active {
         color: $white;
         background-position: 0 33px;
         border-color: transparent;
         background: linear-gradient(to bottom, $blue, $light-blue, $blue);
     }
 }
 .btn-gradient-yellow {
     color: $white;
     background: $yellow;
     border: 0 solid transparent;
     @extend %transition;
     &:hover,
     &:focus,
     &:active {
         color: $white;
         border-color: transparent;
         background: darken($yellow, 5%);
     }
 }

.btn-privat {
    white-space: normal;
    line-height: 1.3;
    border-radius: 6px;
}

 .link-flex {
     display: flex;
     align-items: center;
 }

 @media (max-width: 600px) {
     .btn-big {
         padding: 14px 35px;
     }
 }
