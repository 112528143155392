.actual_landing {
    font-size: 16px;
    line-height: 1.5;
    .landing-title-big {
        font-size: 56px;
        font-family: $ff-bold;
        margin-top: 30px;
        line-height: 1.2;
        p {
            margin: 0;
        }
    }
    .section-padding {
        padding: 100px 0;
        &.with-block {
            padding-bottom: 70px;
        }
    }
    .main-title {
        margin-bottom: 60px;
    }
    .form-cover {
        width: 100%;
        margin: 50px auto;
    }
    .landing-title {
        font-size: 40px;
        color: $title;
        margin-bottom: 20px;
        font-family: $ff-text;
        font-weight: 400;
        line-height: 1.2;
        &.white-title {
            color: $white;
        }
    }
    .landing-subtitle {
        font-size: 24px;
        color: $text-light;
        text-align: center;
        line-height: 1.2;
        p {
            margin: 0;
        }
        h2,h3,h4 {
            font-size: 24px;
            margin: 0 0 10px;
        }
        strong {
            color: $red;
        }
        &.white-title {
            color: $white;
        }
    }
    .landing-video {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .video-info {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        margin-bottom: 30px;
        text-align: center;
    }
    .landing-info-video {
        font-size: 24px;
        margin-bottom: 30px;
        p {
            margin: 0 0 10px;
        }
        ul {
            display: inline-block;
            text-align: left;
            li {
                margin: 0 0 5px;
                position: relative;
                padding-left: 15px;
                line-height: 1.5;
                &::before {
                    content: '';
                    display: block;
                    width: 3px;
                    height: 3px;
                    background: transparent;
                    border: 2px solid $salat;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 13px;
                }
            }
        }
    }
    .want_tour-form {
        max-width: 960px;
        margin: 0 auto;
    }
    .landing-footer {
        background: $landing-footer;
        color: $white;
    }
}

.cover-img {
    width: 100%;
    min-height: 100vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        background: rgba($black, 0.3);
    }
    .want_tour-form .btn-big {
        padding: 18px 35px 19px 35px;
    }
    .cover-img-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: auto 0;
        text-align: center;
    }
    .contact-data-flex {
        text-align: center;
    }
    .contact-info-landing-header {
        padding: 20px 0;
        width: 100%;
    }
    .link-tel-landing-header {
        color: $white;
        font-size: 30px;
        padding: 0 15px;
        @extend %transition;
        &:hover,
        &:focus,
        &:active {
            color: $red;
        }
    }
    .landing-logo {
        background: $white;
        border-radius: 50%;
        width: 175px;
        height: 175px;
        display: flex;
        align-items: center;
        justify-content: center;
        .link-block {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }
    .first-section-landing {
        display: flex;
        position: relative;
        z-index: 1;
        flex-wrap: wrap;
        align-content: stretch;
        position: relative;
    }
    .text {
        font-size: 24px;
        padding: 15px 0;
    }
}

.cost-variant-item {
    height: 100%;
    text-align: center;
    .flex-img {
        background: transparent;
        border: 2px solid $salat;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 20px;
        transform: scale(1);
        @extend %transition;
        svg {
            width: 75px;
            height: 75px;
            fill: $salat;
            @extend %transition;
        }
    }
    .flex-text {
        font-size: 22px;
    }
}

.landing-actual-tile {
    .hotel-name {
        text-align: center;
        color: $title;
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 1.2;
    }
    .text {
        text-align: center;
        color: $text-light;
        font-weight: 700;
        margin-bottom: 15px;
        font-family: $ff-bold;
        font-size: 16px;
    }
    .btn-cover {
        margin-top: auto;
    }
}
.actual_landing-price {
    color: $red;
    font-size: 28px;
    padding: 10px 0;
    .value-price {
        font-family: $ff-bold;
    }
}

.advantages-cover-landing {
    background-color: $text-light;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background: rgba($black, .6);
    }
    .container-cover {
        position: relative;
        z-index: 1;
    }
    .landing-advantages {
        text-align: center;
        padding: 30px 30px 60px;
        height: 100%;
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            background: $white;
            left: 0;
            bottom: 30px;
            top: 0;
        }
    }
    .block-third {
        border-bottom: 1px solid $white;
    }
    .block-third:nth-child(3n+1) {
        .landing-advantages {
            &::after {
                content: none;
            }
        }
    }
    .adv-svg {
        margin: 0 auto;
        width: 70px;
        height: 70px;
        margin-bottom: 20px;
        svg {
            fill: $white;
            width: 70px;
            height: 70px;
        }
    }
    .adv-title {
        font-size: 24px;
        margin-bottom: 20px;
    }
}

.sign-up-cover {
    .block-half {
        &:nth-child(odd) {
            .sign-item {
                &::after {
                    content: none;
                }
            }
        }
        &:nth-child(n+3){
            border-top: 1px solid $blue;
        }
    }
    .sign-item {
        color: $blue;
        text-align: center;
        padding: 60px 30px 30px;
        height: 100%;
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            background: $blue;
            left: -15px;
            bottom: 0;
            top: 30px;
        }
    }
    .sign-svg {
        margin: 0 auto;
        width: 70px;
        height: 70px;
        margin-bottom: 20px;
        svg {
            fill: $blue;
            width: 70px;
            height: 70px;
        }
    }
    .sign-title {
        font-size: 24px;
        margin-bottom: 20px;
    }
}

.landing-reviews-cover {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 30px;
    background: $reviews-landing;
    padding: 30px;
    .landing-reviews {
        display: flex;
    }
    .flex-img {
        border-radius: 50%;
        overflow: hidden;
        flex: 0 0 200px;
        height: 200px;
        margin-right: 30px;
        border: $border;
    }
    .flex-text {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .reviews-title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
    }
    .reviews-text {

    }
    .reviews-name {
        align-self: flex-end;
        margin-top: auto;
        padding-top: 10px;
    }
}

.landing-footer {
    background: $landing-footer;
    color: $white;
    .landing-logo {
        margin-bottom: 60px;
    }
    .contact-address {
        color: $white;
    }
    .contact-item {
        border-color: $white;
    }
    .contact-social {
      margin-top: 50px;
    }
    .social-block {
        justify-content: center;
        .social-link {
            margin: 7.5px;
            background: transparent;
            display: flex;
            align-items: center;
            height: auto;
            width: auto;
            svg {
                width: 45px;
                height: 45px;
                border-radius: 5px;
            }
        }
        .link-text {
            color: $white;
            font-size: 18px;
            padding-left: 15px;

        }
        .link-svg {
            display: block;
            background: $white;
            border-radius: 50%;
            width: 45px;
            height: 45px;
        }
    }
}

@media (max-width:1359px) {
    .adaptive-no-margin {
        margin: 0;
    }
}

@media (max-width:1200px) {
    .actual_landing {
        .landing-title-big {
            font-size: 45px;
        }
        .section-padding {
            padding: 80px 0;
            &.with-block {
                padding-bottom: 50px;
            }
        }
        .main-title {
            margin-bottom: 40px;
        }
    }
}

@media (max-width:1023px) {
    .actual_landing {
        .section-padding {
            padding: 50px 0;
            &.with-block {
                padding-bottom: 20px;
            }
        }
        .landing-title-big {
            font-size: 40px;
        }
        .landing-title {
            font-size: 34px;
        }
        .main-title {
            margin-bottom: 30px;
        }
    }
    .landing-actual-tile {
        .hotel-name {
            font-size: 24px;
        }
    }
    .actual_landing-price {
        font-size: 24px;
    }
    .advantages-cover-landing {
        .block-third:nth-child(3n+1) {
            .landing-advantages {
                &::after {
                    content: '';
                }
            }
        }
        .block-third:nth-child(odd) {
            .landing-advantages {
                &::after {
                    content: none;
                }
            }
        }
    }
    .landing-footer {
        .block-half {
            width: 100%;
        }
        .landing-logo {
            margin-bottom: 30px;
        }
    }
    .cover-img {
        .link-tel-landing-header {
            font-size: 24px;
        }
    }
}

@media (max-width: 767px) {
    .landing-reviews-cover {
        padding: 15px;
        .flex-img {
            height: 150px;
            flex: 0 0 150px;
            margin-right: 15px;
        }
    }
}

@media (max-width:600px) {
    .actual_landing {
        .section-padding {
            padding: 30px 0;
            &.with-block {
                padding-bottom: 15px;
            }
        }
        .landing-title-big {
            font-size: 32px;
            margin-bottom: 0;
            margin-top: 15px;
        }
        .landing-title {
            font-size: 28px;
            margin-bottom: 15px;
        }
        .main-title {
            margin-bottom: 15px;
        }
        .landing-subtitle {
            font-size: 20px;
            h2,h3,h4 {
                font-size: 20px;
            }
        }
        .landing-info-video {
            font-size: 18px;
            margin-bottom: 15px;
        }
        .video-info {
            margin-bottom: 15px;
        }
        .form-cover {
            margin: 15px auto;
        }
    }
    .landing-actual-tile {
        .hotel-name {
            font-size: 20px;
        }
    }
    .actual_landing-price {
        font-size: 20px;
    }
    .cost-variant-item {
        .flex-text {
            font-size: 18px;
        }
        .flex-img {
            margin-bottom: 10px;
        }
    }
    .cover-img {
        .link-tel-landing-header {
            font-size: 20px;
        }
    }
    .landing-footer {
        .landing-logo {
            margin-bottom: 15px;
        }
        .social-block {
            .link-text {
                display: none;
            }
        }
    }
    .sign-up-cover {
        .block-half {
            &:nth-child(odd) {
                .sign-item {
                    &::after {
                        content: none;
                    }
                }
            }
            &:nth-child(n+3){
                border-top: none;
            }
        }
        .block-half + .block-half {
            border-top: 1px solid $blue;
        }
        .sign-item {
            padding: 15px;
            &::after {
                content: none;
            }
        }
        .sign-svg {
            margin-bottom: 10px;
        }
        .sign-title {
            margin-bottom: 10px;
            font-size: 20px;
        }
    }
    .landing-reviews-cover {
        margin-bottom: 15px;
        .landing-reviews {
            flex-direction: column;
            align-items: center;
        }
        .flex-img {
            width: 100px;
            height: 100px;
            flex: 0 0 100px;
            margin: 0 auto;
            margin-bottom: 10px;
        }
        .reviews-title {
            margin-bottom: 10px;
            font-size: 20px;
        }
    }
    .advantages-cover-landing {
        .landing-advantages {
            padding: 15px;
            &::after {
                content: none;
            }
        }
        .block-third:nth-child(3n+1) {
            .landing-advantages {
                padding: 15px;
                &::after {
                    content: none;
                }
            }
        }
        .block-third {
            border-bottom: none;
        }
        .block-third + .block-third {
            border-top: 1px solid $white;
        }
        .adv-svg {
            margin-bottom: 10px;
        }
        .adv-title {
            margin-bottom: 10px;
            font-size: 20px;
        }
    }
    .cover-img {
        .contact-data-flex {
            display: block;
        }
        .link-tel-landing-header {
            display: block;
        }
    }
}
