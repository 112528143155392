// main_sliders start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.main-slider {
    overflow: hidden;
    .slick-arrow-block {
        display: flex;
    }
    .slick-arrow {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        svg {
            fill: $white;
            width: 30px;
            height: 30px;
        }
    }
    .main-slider-next {
        right: 50px;
    }
    .main-slider-prev {
        left: 50px;
    }
    .main-slide {
        position: relative;
    }
    .slide-img {
        position: relative;
        overflow: hidden;
        font-size: 0px;
    }
    .slide-image{
        position: relative;
        overflow: hidden;
        font-size: 0px;
    }
    .slide-image-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    .img-desktop {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
    .img-responsive {
        width: 100%;
    }
    .slide-content-cover {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
    .slide-content {
        text-align: center;
    }
    .slide-title {
        font-size: 60px;
        letter-spacing: 1.8px;
        font-family: $ff-text;
        margin-bottom: 20px;
    }
    .slide-text {
        font-size: 18px;
        font-family: $ff-bold;
        margin-bottom: 20px;
        text-transform: uppercase;
        p {
            margin: 0;
        }
    }
}
// main_sliders finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// partners-slider start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.slick-slider.partners-slider {
    margin-bottom: 50px;
    .slick-track {
        display: flex;
    }
    .slide-item {
        padding: 0 15px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .flex-text {
        margin-top: auto;
        color: $title;
        padding-top: 10px;
        font-size: 20px;
        font-family: $ff-bold;
    }
    .flex-img {
        margin: auto;
    }
    .slick-arrow {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        svg {
            fill: $blue;
            width: 30px;
            height: 30px;
            @extend %transition;
        }
        &:hover {
            svg {
                fill: $light-blue;
            }
        }
    }
    .partners-slider-next {
        right: -50px;
    }
    .partners-slider-prev {
        left: -50px;
    }
    .slick-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: -50px;
        li {
            button {
                &::before {
                    width: 16px;
                    height: 16px;
                    background: transparent;
                    border: 1px solid $salat;
                    border-radius: 50%;
                    color: transparent;
                    display: block;
                    opacity: 1;
                    @extend %transition;
                }
            }
            &.slick-active {
                button {
                    &::before {
                        background: $salat;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
// partners-slider finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//  slider-tours-page Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.slider-tours-page {
    .slick-arrow {
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        background: rgba($white, 0.5);
        svg {
            fill: $light-black;
            width: 30px;
            height: 30px;
            @extend %transition;
        }
        &:hover {
            svg {
                fill: $salat;
            }
        }
    }
    .tours-page-slide-prev {
        left: 0;
    }
    .tours-page-slide-next {
        right: 0;
    }
}
.tours-slider-nav {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 1;
    .gallery-item {
        padding: 5px;
        img {
            border: 2px solid $grey;
        }
        &.slick-current {
            img {
                border-color: $salat;
            }
        }

    }
}
//  slider-tours-page Finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// gallery-block-slick Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.gallery-block-slick {
    .slick-arrow {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid $black;
        @extend %transition;
        svg {
            fill: $light-black;
            width: 30px;
            height: 30px;
            @extend %transition;
        }
        &:hover {
            border-color: $light-blue;
            svg {
                fill: $light-blue;
            }
        }
    }
    .gallery-block-slick-prev {
        left: 30px;
    }
    .gallery-block-slick-next {
        right: 30px;
    }
}
//  gallery-block-slick Finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.slick-arrow {
    svg {
        @extend %transition;
    }
    &:hover,
    &:focus,
    &:active {
        svg {
            fill: $light-blue;
        }
    }
}

@media (max-width: 1200px) {
    .main-slider {
        .slide-title {
            font-size: 50px;
        }
        .main-slider-next {
            right: 0;
        }
        .main-slider-prev {
            left: 0;
        }
    }
    .slick-slider.partners-slider {
        .partners-slider-prev {
            left: -25px;
        }
        .partners-slider-next {
            right: -25px;
        }
    }
}


@media (max-width: 1023px) {
    .main-slider {
        .slide-title {
            font-size: 40px;
        }
    }
}

@media (max-width: 767px) {
    .main-slider {
        .img-mobile {
            height: 90vh;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
        .slick-arrow {
            top: 80%;
        }
    }
}

@media (max-width: 600px) {
    .main-slider {
        .slide-title {
            font-size: 28px;
        }
    }
}
