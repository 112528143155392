// section-blog-item Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!
.section-blog-item {
    .top-line {
        align-items: center;
        justify-content: space-between;
    }
    .blog-date {
        padding: 14px 10px 13px 10px;
        background: $yellow;
        color: $black;
    }
    .last-news-cover {

    }
}
.link-flex {
    display: flex;
    align-items: flex-start;
    @extend %transition;
    .flex-img {
        flex: 0 0 65px;
        margin-right: 10px;
    }
    .flex-text {
        flex: 1;
    }
    .subtitle-sidebar {
        font-size: 16px;
        font-family: $ff-bold;
        margin-bottom: 3px;
        @extend %transition;
    }
    .date-sidebar {
        color: $text-light;
        font-size: 14px;
    }
}
.link-flex + .link-flex {
    margin-top: 30px;
}
// section-blog-item finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!
// why-we-page Start!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.advantages-item {
    border: $border;
    padding: 20px 15px;
    text-align: center;
    height: 100%;
    @extend %transition;
    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    .adv-subtitle {
        color: $title;
        font-family: $ff-bold;
        font-size: 20px;
        margin-bottom: 15px;
    }
    .adv-svg {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        margin-bottom: 15px;
        svg {
            fill: $red;
            width: 50px;
            height: 50px;
        }
    }
}
// why-we-page Finish!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// section-tour-item Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.section-tour-item {
    .galeria-tour-cover {
        padding: 0 15px;
        width: 65%;
        max-width: 650px;
    }
    .chars-tours-cover {
        padding: 0 15px;
        width: 35%;
    }
    .margin-desc {
        margin-top: 50px;
    }
    .galeria-tour-main {
        position: relative;
    }
}

.chars-tours-cover {
    .tour-price {
        padding: 10px 25px;
        background: $yellow;
        display: inline-block;
        position: relative;
        margin-bottom: 30px;
        margin-left: 30px;
        align-self: flex-start;
        &:before {
            content: '';
            display: block;
            border: 39px solid transparent;
            border-right: 39px solid $yellow;
            position: absolute;
            top: 0;
            left: -78px;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            background: $white;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            top: 50%;
            transform: translateY(-50%);
            left: -6px;
        }
    }
    .price {
        font-size: 30px;
        color: $title;
        font-family: $ff-bold;
        white-space: nowrap;
        // display: inline-block;
    }
    .tour-people {
        // display: inline-block;
    }
    .char-item {
        font-size: 18px;
        display: flex;
        align-items: center;
    }
    .char-item + .char-item {
        margin-top: 10px;
    }
    .char-svg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 25px;
        padding-right: 10px;
        svg {
            fill: $blue;
            width: 18px;
            height: 18px;
        }
    }
    .char-name {
        color: light-text;
        padding-right: 5px;
        align-self: flex-end;
    }
    .char-value {
        color: $title;
        align-self: flex-end;
    }
    .chars-tour {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .btn-cover {
        margin-top: auto;
        padding-top: 20px;
    }
}
//  section-tour-item Finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//  popular-item START !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.popular-item {
    position: relative;
    box-shadow: 0 0 40px rgba(0, 0, 0, .2);
    @extend %transition;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($black, .45);
        @extend %transition;
    }
    .popular-text {
        color: $white;
        text-shadow: 1px 1px 0 rgba(0,0,0,0.25), 0 1px 5px rgba(0,0,0,0.5);
        font-size: 20px;
        font-family: $ff-bold;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px;
        text-align: center;
        pointer-events: none;
        z-index: 1;
    }
}
//  popular-item FINISH !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// 404 and 500 !!!!!!!!!!!!!!!!!!!!
.section-mistake {
    padding: 100px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    .title-mistake {
        font-family: $ff-bold;
        font-size: 150px;
        line-height: 1;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    .subtitle-mistake {
        font-family: $ff-bold;
        font-size: 30px;
        text-transform: uppercase;
    }
    .text-mistake {
        font-size: 30px;
        font-family: $ff-bold;
        text-transform: uppercase;
    }
    .link-mistake {
        color: $green;
    }
}
// 404 and 500 !!!!!!!!!!!!!!!!!!!!
// Авиабилеты !!!!!!!!!!!!!!!!!!!!!!!!
.form-cover-help {
    background: $bg-grey;
    border: $border;
    padding-left: 15px;
    padding-right: 15px;
}
// Авиабилеты !!!!!!!!!!!!!!!!!!!!!!!!
// Contact !!!!!!!!!!!!!!!!!!!!!!!!!!
.section-contact {
    .forms-contact {
        max-width: 300px;
        margin: 0 auto;
    }
    .feedback-form  {
        padding: 30px;
    }
    .contact-social {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .social-block {
        .social-link {
            margin: 7.5px;
            background: transparent;
            display: flex;
            align-items: center;
            height: auto;
            width: auto;
            svg,
            img {
                width: 45px;
                height: 45px;
            }
        }
        .link-text {
            color: $text;
            font-size: 18px;
            padding-left: 15px;

        }
        .link-svg {
            display: block;
        }
    }
    .contact-soc-outer + .contact-soc-outer {
        margin-top: 50px;
    }
}

.contact-item {
    border: $border;
    display: flex;
    flex-direction: column;
    height: 100%;
    .contact-info {
        padding: 30px;
    }
    .contact-address {
        font-size: 20px;
        font-family: $ff-bold;
        color: $title;
        margin-bottom: 20px;
        text-align: center;
        p {
            margin: 0;
        }
    }
    .contact-data-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .link-tel {
        font-size: 20px;
    }
    .link-tel + .link-tel {
        margin-left: 0;
        margin-top: 10px;
    }
    .video-item {
        margin-top: auto;
        padding-bottom: 35%;
    }
    .contact-note {
        margin-top: 10px;
        p {
            margin: 0;
        }
    }
}

.employer-item {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 100%;
    .flex-text {
        padding: 20px;
        text-align: center;
    }
    .employer-name {
        font-size: 20px;
        color: $title;
        margin-bottom: 15px;
    }
    .employer-info {
        p {
            margin: 0 0 10px;
            color: $text-light;
            a {
                color: $salat;
                font-family: $ff-bold;
                @extend %transition;
                &:hover,
                &:focus,
                &:active {
                    color: $green;
                }
            }
        }

    }
}
// Contact !!!!!!!!!!!!!!!!!!!!!!!!!!!!
// cruise_list !!!!!!!!!!!!!!!!!!!!!!!!!
.cruise_list {
    .mobile-cruise-name {
        display: none;
    }
    .flex-text-cover {
        padding: 30px 0;
    }
    .chars-tours-cover {
        width: 45%;
        padding: 0 15px;
    }
    .tour-price {
        white-space: nowrap;
        &::before {
            border-width: 30px;
            border-right-width: 30px;
            left: -60px;
        }

    }
    .block-info-cover {
        width: 55%;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
    }
    .tour-tile {
        border: none;
        border-bottom: $border;
    }
    .admin-desc {
        margin-top: 0;

    }
    .date-publication {
        margin-top: auto;
        align-self: flex-end;
        padding-top: 20px;
    }
    .date-publication-value {
        color: $blue;
        font-size: 18px;
        font-family: $ff-bold;
    }
    .price {
         display: inline-block;
    }
    .tour-people {
        display: inline-block;
    }

}
// cruise_list !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// section_service-list !!!!!!!!!!!!!!!!!!!
.section_service-list {
    .block-third {
        max-width: 340px;
    }
}
//  section_service-list !!!!!!!!!!!!!!!!!!!!!!!!!
