// blog_item START !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.blog_item {
    height: 100%;
    box-shadow: 0 0 40px rgba(0, 0, 0, .1);
    position: relative;
    .flex-text {
        padding: 30px 15px;
    }
    .blog-title {
        font-size: 20px;
        display: block;
        margin-bottom: 10px;
        font-family: $ff-bold;
    }
    .blog-date {
        margin-bottom: 10px;
        font-size: 13px;
        color: $blue;
    }
    .blog-desc {
        font-size: 15px;
        color: $text-light;
        margin-bottom: 10px;
    }
    .icon-svg {
        display: inline-flex;
        align-items: center;
        margin-left: 5px;
        svg {
            width: 13px;
            height: 15px;
            fill: $salat;
            @extend %transition;
        }
    }
    .blog-more {
        position: absolute;
        right: 10px;
        bottom: 5px;
        display: flex;
        align-items: center;
        color: $salat;
        @extend %transition;
        &:hover,
        &:focus,
        &:active {
            color: darken($salat, 20%);
            svg {
                fill: darken($salat, 20%);
            }
        }
    }
}
// blog_item FINISH !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// service_item Start !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.service_item {
    height: 100%;
    position: relative;
    overflow: hidden;
    @extend %transition;
    @include pseudo-fon-short;
    &::after {
        background: $black;
        opacity: 0.35;
    }
    .about-service {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        text-align: center;
        z-index: 1;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        transition: all .5s ease;

    }
    .service-title {
        font-size: 20px;
        margin-bottom: 10px;
        font-family: $ff-bold;

    }
    .service-text {
        opacity: 0;
        display: none;
        transition: all .5s ease;
    }
    .blur {
        filter: blur(0);
        transition: all .5s ease;
    }
    &:hover {
        .blur {
            filter: blur(3px);
        }
        &::after {
            opacity: 0.45;
        }
        .about-service {
            transform: translateY(-40%);
        }
        .service-text {
            opacity: 1;
            display: block;
        }
    }
}
// service_item Finish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
