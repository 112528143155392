.tour-tile {
    width: 100%;
    border: $border;
    height: 100%;
    display: flex;
    flex-direction: column;
    .flex-text {
        padding: 15px 10px;
    }
    .block-info {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .price {
        text-align: right;
        margin-left: 10px;
    }
    .price-value {
        font-size: 20px;
        font-family: $ff-bold;
        color: $salat;
        text-align: right;
        white-space: nowrap;
    }
    .price-people {
        white-space: nowrap;
        font-size: 12px;
        color: $text-light;
    }
    .country-name {
        margin-bottom: 3px;
    }
    .hotel-name {
        font-size: 20px;
        font-family: $ff-bold;
    }
    .block-char {
        margin-top: 10px;
    }
    .char-item {
        font-size: 13px;
        display: flex;
        align-items: end;
    }
    .char-item + .char-item {
        margin-top: 7.5px;
    }
    .char-svg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 25px;
        padding-right: 5px;
        svg {
            fill: $blue;
            width: 15px;
            height: 15px;
        }
    }
    .char-name {
        color: light-text;
        padding-right: 5px;
        align-self: flex-end;
    }
    .char-value {
        color: $title;
        align-self: flex-end;
    }
    .btn-block {
        margin-top: auto;
    }
}

.tours_slider {
    .slick-arrow {
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        background: rgba($white, 0.5);
        @extend %transition;
        svg {
            fill: $light-black;
            width: 30px;
            height: 30px;
            @extend %transition;
        }
        &:hover {
            svg {
                fill: $light-black;
            }
        }
    }
    .tours-slider-prev {
        left: 0;
    }
    .tours-slider-next {
        right: 0;
    }
}
