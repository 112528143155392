@media (max-width: 1280px){
    .header-bottom {
        .header-name-company {
            font-size: 20px;
            margin: 0;
            margin-right: auto;
        }
        .header-address-cover {
            margin: 0;
            margin-right: auto;
        }
    }
    .bottom-menu {
        .bottom-menu-link {
            font-size: 12px;
            padding: 7.5px 5px
        }
    }
}


@media (max-width:1200px) {
    .section-blog-item {
        .block-big-seven {
            max-width: 965px;
            margin: 0 auto;
            margin-bottom: 30px;
        }
    }
    .cruise_list {
        .block-big-seven {
            max-width: 965px;
            margin: 0 auto;
        }
    }
    .section-tour-item {
        .block-big-seven {
            max-width: 965px;
            margin: 0 auto;
            margin-bottom: 30px;
        }
    }
}



@media (max-width: 1023px) {
    .breadcrumbs-cover {
        padding: 15px 0;
    }
    .action-tile {
        .flex-img {
            flex: 0 0 250px;
        }
    }
    aside.block-fourth {
        margin-top: 15px;
    }
    .section-contact {
        .block-half {
            width: 100%;
        }
    }
    .section_tours {
        .tours_list {
            justify-content: center;
        }
    }
    .section-tour-item {
        .tours-page-container {
            flex-direction: column;
            align-items: center;
        }
        .galeria-tour-cover {
            width: 100%;
            margin-bottom: 30px;
        }
        .chars-tours-cover {
            width: 100%;
            max-width: 450px;
            .tour-price {
                align-self: stretch;
                text-align: center;
            }
        }
    }
    .cruise_list  {
        .cruise-info  {
            display: block;
        }
        .chars-tours-cover,
        .block-info-cover {
            width: 100%;
        }
        .chars-tours-cover {
            margin-bottom: 15px;
            max-width: 100%;
        }
        .desctop-cruise-name {
            display: none;
        }
        .mobile-cruise-name {
            display: block;
            padding-right: 30px;
        }
        .cover-cruise-price {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .chars-tours-cover {
            .sidebar-title,
            .tour-price {
                margin-bottom: 15px;
            }
        }
    }
}


@media (max-width: 767px) {
    .gallery-block-slick {
        .block-third {
            max-width: 100%;
        }
    }
    .cruise_list  {
        .flex-text-cover {
            padding: 15px 0;
        }
        .cover-cruise-price {
            display: block;
        }
        .chars-tours-cover {
            .mobile-cruise-name {
                padding-right: 0;
            }
            .char-item {
                font-size: 15px;
            }
            .tour-price {
                display: flex;
                align-items: center;
                flex-direction: column;
                max-width: 320px;
                padding: 9px 25px;
            }
            .price {
                font-size: 18px;
            }
        }
        .date-publication {
            padding-top: 10px;
            display: flex;
            flex-direction: column;
        }
        .date-publication-value {
            align-self: flex-end;
        }

    }
    .contact-item .video-item {
      padding-bottom: 56.25%;
    }
}



@media (max-width: 600px) {
    .blog_item {
        .flex-text {
            padding: 15px;
        }
    }
    .breadcrumbs-cover {
        padding: 0;
    }
    .section-blog-item {
        .top-line {
            flex-direction: column;
        }
        .cover-date {
            align-self: flex-end;
            margin-bottom: 15px;
        }
        .block-big-seven {
            margin-bottom: 15px;
        }
    }
    .margin-desc + .margin-desc {
        margin-top: 15px;
    }
    .admin-desc {
        p {
            img {
                margin-bottom: 10px;
            }
            iframe {
                margin-bottom: 10px;
            }
        }
        h2 {
            margin: 0 0 10px;
            font-size: 22px;
        }
        h3 {
            margin: 0 0 10px;
            font-size: 22px;
        }
    }
    .action-tile {
        display: block;
        max-width: 465px;
        margin: 0 auto;
        .flex-img {
            flex: 0 0 auto;
            margin-bottom: 10px;
            margin-right: 0;
        }
        .action-title {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    .action-cover + .action-cover {
        padding-top: 15px;
    }
    .action-cover {
        margin-bottom: 15px;
    }
    aside.block-fourth {
        margin: 15px auto;
    }
    .link-flex + .link-flex {
        margin-top: 10px;
    }
    .transfer-form-cover {
        padding: 15px;
        margin: 15px 0;
        .transfer-form-text {
            font-size: 15px;
        }
        .block-100 {
            text-align: center;
        }
    }
    .section-mistake {
        .title-mistake {
            font-size: 100px;
        }
        .subtitle-mistake,
        .text-mistake {
            font-size: 20px;
        }
    }
    .employer-item {
        .flex-text {
            padding: 15px;
        }
    }
    .section-contact {
        .feedback-form {
            padding: 15px;
        }
        .contact-soc-outer + .contact-soc-outer {
            margin-top: 15px;
        }
        .social-block {
            justify-content: center;
            .link-text {
                display: none;
            }
        }
    }
    .contact-item {
        .contact-info {
            padding: 15px;
        }
        .contact-address {
            font-size: 15px;
            margin-bottom: 10px;
        }
        .link-tel {
            font-size: 15px;
        }
        .link-tel + .link-tel {
            margin-top: 5px;
        }
        .contact-note {
            text-align: center;
        }
    }
    .section-tour-item {
        .chars-tours-cover {
            .tour-price {
                align-self: stretch;
                margin-bottom: 15px;
                &::before {
                    border: 31px solid transparent;
                    border-right: 31px solid #FDC300;
                    left: -62px;
                }
            }
            .price {
                font-size: 18px;
            }
        }
        .margin-desc {
            margin-top: 15px;
        }
        .block-big-seven {
            margin-bottom: 15px;
        }
        .tours-slider-nav {
            bottom: -15px;
        }
    }
}
