.item-aside + .item-aside {
    margin-top: 20px;
}

.cover-question {
    padding: 30px 15px;
    background: $bg-grey;
    border: $border;
}

.block-tel-aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    .link-tel-aside + .link-tel-aside {
        margin-top: 10px;
    }
    .link-tel-aside {
        font-family: $ff-bold;
        font-size: 20px;
    }
}
