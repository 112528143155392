.action-cover + .action-cover {
    padding-top: 30px;
    border-top: $border;
}

.action-cover {
    margin-bottom: 30px;
}

.action-tile {
    display: flex;
    .flex-img {
        flex: 0 0 auto;
        margin-right: 15px;
    }
    .flex-text {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .action-title {
        font-size: 26px;
        font-family: $ff-bold;
        line-height: 1.1;
        margin-bottom: 15px;
    }
    .action-term {
        color: $text-light;
    }
    .action-text {
        margin-top: 15px;
    }
    .cover-btn {
        margin-top: auto;
        padding-top: 15px;
        text-align: right;
    }
}
