
.input-hidden {
    display: none;
}
.flex-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.span-placeholder {
    position: absolute;
    top: 15px;
    left: 20px;
    color: $title;
    font-family: $ff-text;
    font-weight: normal;
    @extend %transition;
    pointer-events: none;
}

.form-input {
    border: none;
    outline: none;
    background: none;
    background-color: none;
    width: 100%;
    padding: 17px 20px;
    font-size: 16px;
    height: 49px;
    &.textarea {
        height: 100px;
    }
    &:focus,
    &:hover,
    &:active {
        border: none;
        outline: none;
        background: none;
        background-color: none;
    }
}

.selectric {
    background: transparent;
    border: none;
    .label {
        font-size: 15px;
        height: 100%;
        font-weight: normal;
        color: $title;
        font-family: $ff-text;
        text-align: left;
    }
    &.open {
        border-color: $salat;
    }
    .button {
        background: transparent;
        height: 100%;
    }
}
.selectric-items ul, .selectric-items li {
    font-size: 15px;
}
.selectric-items li:first-child {
    display: none !important;
}
.selectric-items {
    border:  $border;
    background: $white;
    box-shadow: none;
}
.input-wrapper {
    padding-bottom: 25px;
    position: relative;
    width: 100%;
    height: 100%;
    &.no-pad {
        padding-bottom: 0;
    }
}

.input-block {
    background: $white;
    border: $border;
    color: $text;
    position: relative;
    @extend %transition;
    &:focus,
    &:hover,
    &:active {
        border-color: $salat;
    }
}


.span__error {
    position: absolute;
    color: $white;
    font-size: 11px;
    left: 10px;
    top: 100%;
}

.success {
    text-align: center;
    color: $bg-grey;
    margin-top: 20px;
    display: none;
    .middle-title {
        color: $text;
    }
    &.big-success {
        font-size: 16px;
        color: $text;
        font-family: $ff-bold;
    }
}

.radio-group {
    margin-bottom: 20px;
}

.radio + .radio {
    margin-top: 10px;
}
.radio-label {
    font-size: 14px;
    line-height: 24px;
    color: $text;
    position: relative;
    display: block;
    padding-left: 30px;
    &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: $border;
        background: $white;
        position: absolute;
        top: 0;
        left: 0;
    }
}
input:checked + .radio-label {
    &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background: $salat;
        position: absolute;
        top: 5px;
        left: 5px;
    }
}

.radio_butt:disabled + .radio-label {
    color: $bg-grey;
    &::after {
        opacity: .5;
    }
}

.filter-label {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    color: $title;
    font-size: 15px;
    line-height: 20px;
    vertical-align: middle;
    font-family: $ff-text;
    font-weight: normal;
    cursor: pointer;
    @extend %transition;
    &::before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        border: 1px solid $title;
        background: $white;
        position: absolute;
        left: 0;
        top: 0;
    }
    &:hover {
        &::before  {
            border: 1px solid $salat;
        }
    }
}
input[type="checkbox"]:checked + label.filter-label {
    color: $salat;
    &::after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: $salat;
        position: absolute;
        left: 3px;
        top: 3px;
    }
}

// want_tour-form !!!!!!!!!!!!!!!!!!!!!!
.want_tour-form {
    .input-group-wrapper {
        position: relative;
    }
    .inputs-list {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        margin: 0 -12.5px;
        justify-content: flex-end;
        flex-wrap: wrap;
        &.center {
            justify-content: center;
        }
    }
    .input-wrapper {
        padding: 0 12.5px 25px 12.5px;
        max-width: 320px;
        &.no-pad {
            padding-bottom: 0;
        }
    }
    .success {
        position: absolute;
        left: 0;
        right: 0;
        &.black {
            color: $title;
        }
    }
    .btn-big {
        padding: 17px 35px 18px 35px;
    }
    .span__error {
        color: $white;
    }
    &.red_error {
        .span__error {
            color: $red;
        }
    }
}

.title-input-group {
    margin-bottom: 15px;
    font-size: 22px;
    font-family: $ff-bold;
    color: $text;
}

.main-callback-form-cover {
    .diler-text {
        &.mini-margin {
            margin-bottom: 20px;
        }
    }
    .main-callback-form {
        .input-group-wrapper {
            max-width: 900px;
        }
    }
}

.btn {
    &.hidden {
        display: none;
    }
}

.cover-form-want-tour {
     background: linear-gradient(to bottom, rgba(255, 191, 0, .85), rgba(255, 0, 0, .85));
     padding: 30px 15px;
     .span__error {
         color: $white;
         left: 0;
     }
     .forms__error {
         .input-block {
             border-color: $red;
         }
     }
}

.white-form {
    .input-block {
        background: rgba($black, .45);
        border: 2px solid $white;
        color: $white;
    }
    .span-placeholder {
        color: $white;
    }
    .form-input {
        color: $white;
    }
}

.form-checkbox-cover {
    height: 100%;
    display: flex;
    align-items: center;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
    width: 300px;
}

.transfer-form-cover {
    padding: 30px;
    margin: 30px 0;
    background: #f9f9f9;
    border: 1px solid #ebebeb;
    .transfer-phone {
        color: $text-light;
        font-size: 13px;
        font-family: $ff-bold;
        margin-top: 10px;
    }
    .transfer-form-text {
        text-align: center;
        color: $title;
        font-family: $ff-bold;
        font-size: 18px;
    }
    .success {
        &.black {
            color: $title;
            text-align: center;
            font-family: $ff-bold;
            font-size: 18px;
        }
    }
    .span__error {
        color: $red;
    }
}
