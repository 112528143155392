$blue: #0158A7;
$light-blue: #009FE3;
$red: #E3000B;
$yellow: #FDC300;
$green: #00752F;
$salat: #69AF28;
$light-black: #1f1f1f;


$white: #fff;
$black: #000;
$grey: #494b4a;
$bg-grey: #f9f9f9;
$reviews-landing: #efefef;
$landing-footer: #242625;

$text-light: #999;
$title: #303030;
$text: #777;

$border: 1px solid #ebebeb;

$font-size: 15px;
$ff-light: 'latolight', sans-serif;
$ff-text: 'latoregular', sans-serif;
$ff-bold: 'latobold', sans-serif;

%transition {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@mixin pseudo-fon-short {
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($white,.2);
        pointer-events: none;
        opacity: 0;
        @extend %transition;
    }
}

@mixin pseudo-fon {
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($white,.2);
        pointer-events: none;
        opacity: 0;
        @extend %transition;
    }
    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}

@include placeholder {
    color: $text;
    opacity: .8;
    font-size: $font-size;
    font-family: $ff-text;
}

//  Анимация пульсация  - на кнопках слайдера CORRAL!!!!!!!
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($red, 0.9);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba($red, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba($red, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($red, 0.9);
    box-shadow: 0 0 0 0 rgba($red, 0.9);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba($red, 0);
      box-shadow: 0 0 0 10px rgba($red, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba($red, 0);
      box-shadow: 0 0 0 0 rgba($red, 0);
  }
}
