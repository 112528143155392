.footer {
    padding: 50px 0;
    background: $light-black;
    color: $white;
    &.landing {
        display: none;
    }
    .link-tel-cover + .link-tel-cover {
      margin: 10px 0 0;
    }
    .menu-footer {
        margin: -3px -5px;
    }
    .logo {
        margin-bottom: 20px;
    }
    .menu-footer-link {
        display: inline-block;
        padding: 3px;
    }
    .container-flex {
        align-items: flex-start;
    }
    .footer-title {
        font-size: 20px;
        font-family: $ff-bold;
        margin-bottom: 20px;
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            background: $yellow;
            left: 0;
            width: 15%;
        }
        &.no-margin {
            margin-bottom: 0;
        }
    }
    .logo {
        .img-responsive {
            margin: 0;
        }
    }
    .item-address + .item-address {
        border-top: 2px solid $yellow;
        margin-top: 10px;
        padding-top: 10px;
    }
    .link-tel + .link-tel {
        margin-left: 0;
    }
    .link-tel {
        margin-right: 7.5px;
    }
    .link-address {
        cursor: pointer;
        p {
            margin: 0 0 10px;
        }
    }
    .partners-footer {
        .slick-track {
            margin: 0 -10px;
             display: flex;
             flex-direction: column;
            align-items: center;
        }
        img {
            margin: 0;
            max-width: 150px;
        }
    }
    .partners-item {
        padding: 20px 10px;
        min-height: 130px;
        display: flex;
        align-items: center;
    }
    .social-block {
        justify-content: flex-start;
    }
    .footer-info + .footer-info {
        margin-top: 30px;
    }
    .btn-privat {
        max-width: 280px;
        padding: 10px;
    }
    .social-block .social-link {
        height: 35px;
        width: 35px;
        svg {
            width: 35px;
            height: 35px;
        }
    }
}
.copyright {
    width: 100%;
    padding: 0 15px;
    padding-top: 10px;
    margin-top: 10px;
    border-top: $border;
}

@media (max-width: 1200px) {
    .footer  {
        .block-fourth  {
            margin-bottom: 30px;
            width: 50%;
            text-align: center;
        }
        .footer-title {
            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .logo {
            .img-responsive {
                margin: 0 auto;
            }
        }
        .social-block {
            justify-content: center;
        }
        .partners-item {
            justify-content: center;
        }
        .img-responsive.not_margin {
            margin:  0 auto;
        }
        .partners-footer img {
            margin: 0 auto;
        }
    }
    .copyright {
        text-align: center;
    }
}

@media (max-width: 600px) {
    .footer  {
        padding: 25px 0;
        .block-fourth  {
            margin-bottom: 15px;
            width: 100%;
        }
        .footer-title {
            margin-bottom: 15px;
            &.no-margin {
                margin-bottom: 15px;
            }
        }
        .partners-item {
            padding: 0;
        }
    }
}
