@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import 'fonts';
@import "~normalize.css/normalize.css";
@import "variables";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~lightcase/src/css/lightcase.css";
@import "~selectric/src/selectric.scss";
@import "~jquery-datetimepicker/jquery.datetimepicker.css";

// @import "new";
@import "main";
@import "components";
@import "forms";
@import "header";
@import "header_mobile";
@import "footer";
@import "sidebar";
@import "sliders";
@import "tour_tile";
@import "action_tile";
@import "want_tour";
@import "blog_item";
@import "unit_pages";
@import "landing";
@import "modal";
@import "modules";
@import "media";


.header.js_hide {
    display: none;
}

.js_hide {
    display: none;
}
